<template>
  <Drawer title="Job配置信息" v-model="isShow" width="600" scrollable>
    <Row class="text-cell">
      <Col class="title" span="3">Job名称:</Col>
      <Col class="value" span="8">{{ obj.JobName }}</Col>
      <Col class="title" span="3">状态</Col>
      <Col class="value" span="9">{{ obj.StatusCN }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">运行间隔</Col>
      <Col class="value" span="8">{{ obj.TimeSpace }}</Col>
      <Col class="title" span="3">时间单位</Col>
      <Col class="value" span="9">{{ obj.TimeUnitCN }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">描述</Col>
      <Col class="value" span="20">{{ obj.Summary }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">最后运行</Col>
      <Col class="value" span="20">{{ obj.LastRunTime }}</Col>
    </Row>
  </Drawer>
</template>
<script>
export default {
  name: "job-detail-drawer",
  data() {
    return {
      isShow: false,
      obj: {},
    };
  },
  watch: {
    isShow: function (val) {
      if (val) {
        // this.GetRedis();
      }
    },
  },
  methods: {},
  computed: {},
};
</script>
