<template>
  <Modal
    v-model="isShow"
    title="Job配置"
    loading
    width="600"
    class-name="i-modal"
    :maskClosable="false"
    @on-ok="handleSave"
  >
    <Form :model="obj" ref="cacheForm" :label-width="90" :rules="validateRules">
      <Row :gutter="16" prop="JobName">
        <Col span="12">
          <FormItem label="Job名称:" prop="JobName"
            ><Input v-model="obj.JobName"
          /></FormItem>
        </Col>
        <Col span="12" prop="Status">
          <FormItem label="状态:" prop="Status">
            <Select transfer v-model="obj.Status">
              <Option :value="10">启用</Option>
              <Option :value="20">禁用</Option>
            </Select></FormItem
          >
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12" prop="TimeSpace">
          <FormItem label="间隔时间:"
            ><Input v-model="obj.TimeSpace"
          /></FormItem>
        </Col>
        <Col span="12">
          <FormItem label="时间单位:" prop="TimeUnit">
            <Select transfer v-model="obj.TimeUnit">
              <Option :value="1">秒</Option>
              <Option :value="2">分钟</Option>
              <Option :value="3">小时</Option>
              <Option :value="4">天</Option>
              <Option :value="5">月</Option>
              <Option :value="6">年</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="24">
          <FormItem label="描述:"> <Input v-model="obj.Summary" /></FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="24">
          <FormItem label="最后运行时间:">
            <Input v-model="obj.LastRunDate" disabled
          /></FormItem>
        </Col>
      </Row>
    </Form>
    <template slot="footer">
      <Button type="default" @click="handleCancel">取消</Button>
      <Button type="primary" :loading="isUploading" @click="handleSave"
        >保存</Button
      >
    </template>
  </Modal>
</template>

<script>
import api from "@/api";
export default {
  name: "job-edit",
  data() {
    return {
      isShow: false,
      isUploading: false,
      obj: {},
      validateRules: {
        JobName: [
          { required: true, message: "Job名称不能为空 ", trigger: "blur" },
        ],
      },
    };
  },
  watch: {},
  methods: {
    async handleSave() {
      const _this = this;
      this.$refs.cacheForm.validate(async (valid) => {
        if (valid) {
          try {
            this.isUploading = true;
            const res = await api.SaveJobConfig(this.obj);
            if (res.Status === 100) {
              this.isUploading = false;
              setTimeout(function () {
                _this.$emit("on-save", this.obj);
              }, 800);
              this.$Message.success("保存成功");
              this.isShow = false;
            } else {
              this.$Notice.error({
                title: "错误",
                desc: res.Msg,
              });
            }
          } catch (error) {
            console.log(error);
            this.$Notice.error({
              title: "错误",
              desc: error,
            });
            this.isShow = false;
            this.isUploading = false;
          }
        } else {
          this.$Message.error("填写信息有误！");
        }
      });
    },
    handleCancel() {
      this.$refs.cacheForm.resetFields();
      this.isShow = false;
    },
  },
  computed: {},
};
</script>
