<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="70"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="Job名称:" prop="JobName">
              <Input
                placeholder="请输入Job名称"
                v-model="params.JobName"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="状态:" prop="Status">
              <Select clearable v-model="params.Status" placeholder="状态">
                <i-option :value="10">启用</i-option>
                <i-option :value="20">禁用</i-option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="描述:" prop="Summary">
              <Input
                placeholder="请输入Job描述"
                v-model="params.Summary"
                clearable
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left">
          <Button
            type="success"
            icon="md-add-circle"
            v-if="$canAction(['Sys_Job_Edit'])"
            @click="openJobAdd"
          >
            新增
          </Button>
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      stripe
      class="mt10 main-table"
      @on-sort-change="sortChange"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      size="small"
    >
      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          type="primary"
          v-if="$canAction(['Sys_Job_Search'])"
          @click="openDrawer(row)"
        >
          查看
        </Button>
        <Button
          class="ml2"
          size="small"
          type="success"
          v-if="$canAction(['Sys_Job_Edit'])"
          @click="openJobEdit(row)"
        >
          编辑
        </Button>
        <Button
          class="ml2"
          size="small"
          type="error"
          v-if="$canAction(['Sys_Job_Del'])"
          @click="showDelete(row)"
        >
          删除
        </Button>
      </template>
      <template slot="State" slot-scope="{ row }">
        <Tag
          type="border"
          :color="
            row.Status === 10 ? 'green' : row.Status === 20 ? 'red' : 'default'
          "
        >
          {{ row.StatusCN }}
        </Tag>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <job-edit @on-save="loadList" ref="jobEdit" />
    <detail-drawer ref="detailDrawer" />
  </Layout>
</template>
<script>
import Layout from "@/components/layout/index.vue";
import api from "@/api";
import detailDrawer from "./components/jobconfig-drawer.vue";
import jobEdit from "./components/jobconfig-edit.vue";
export default {
  name: "JobManage",
  data() {
    return {
      tableLoading: false,
      tableHeight: 0,
      modalDelete: false, // 删除窗口状态
      DeleteLoading: false, // 删除按钮状态
      modalClear: false, // 清除缓存模态框状态
      modalImport: false,
      selectedRow: {},
      params: {
        CacheName: "",
        CacheKey: "",
        State: null,
        Page: 1,
        PageSize: 40,
        SortName: "ID",
        SortOrder: "Asc",
      },
      columns: [
        {
          title: "ID",
          key: "ID",

          align: "center",
          sortable: "custom",
          minWidth: 65,
        },
        {
          title: "Job名称",
          key: "JobName",

          align: "center",
          minWidth: 100,
        },
        {
          title: "运行间隔",
          key: "TimeSpace",

          align: "center",
          sortable: "custom",
          minWidth: 90,
        },
        {
          title: "时间单位",
          key: "TimeUnitCN",

          align: "center",
          minWidth: 70,
        },
        {
          title: "状态",
          key: "Status",
          slot: "State",
          align: "center",
          minWidth: 100,
          sortable: "custom",
        },
        {
          title: "描述",
          key: "Summary",
          minWidth: 135,

          align: "center",
          // sortable: 'custom'
        },
        {
          title: "上次运行时间",
          key: "LastRunTime",
          minWidth: 135,

          align: "center",
          // sortable: 'custom'
        },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
    };
  },
  computed: {},
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        minWidth: this.$route.meta.tableActionWidth,
        maxWidth: this.$route.meta.tableActionWidth,
        fixed: "right",
      });
    }
    this.loadList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);
        this.loadList();
      }
    });
  },
  activated() {
    const self = this;
    window.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadList();
      }
    });
  },
  deactivated() {
    window.removeEventListener("keydown", function () {});
  },
  methods: {
    openDrawer(row) {
      this.$refs.detailDrawer.obj = row;
      this.$refs.detailDrawer.isShow = true;
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadList();
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadList();
    },
    async loadList() {
      this.tableLoading = true;
      try {
        const res = await api.GetJobConfigList(this.params);
        if (res.Status === 100) {
          this.resObj = res.Data;
          this.tableLoading = false;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    openJobEdit(row) {
      this.$refs.jobEdit.$refs.cacheForm.resetFields();
      this.$refs.jobEdit.obj = JSON.parse(JSON.stringify(row)); // 去掉双向数据绑定
      this.$refs.jobEdit.isShow = true;
    },
    openJobAdd() {
      this.$refs.jobEdit.$refs.cacheForm.resetFields();
      this.$refs.jobEdit.obj = {}; // 去掉双向数据绑定
      this.$refs.jobEdit.isShow = true;
    },
    // 修改成功，更改列表数据
    handleEditSuccess(res) {
      const index = this.resObj.DataList.findIndex(
        (item) => item.ID === res.ID
      );
      if (index >= 0) {
        this.resObj.DataList.splice(index, 1, res);
      } else {
        this.resObj.DataList.push(res);
      }
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadList();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.loadList();
    },
    closeModal() {
      this.$Modal.remove();
    },
    showDelete(row) {
      this.selectedRow = row;
      this.$Modal.confirm({
        title: "删除Job配置确认",
        closable: true,
        content: "Job配置删除后不能恢复，确认删除吗?",
        onOk: this.deleteJob,
        cancelText: "取消",
        onCancel: () => {
          this.$Modal.remove();
        },
      });
    },
    async deleteJob() {
      const _this = this;
      try {
        const res = await api.DeleteJobConfig({
          id: this.selectedRow.ID,
        });
        if (res.Status === 100) {
          _this.closeModal();
          const index = _this.resObj.DataList.findIndex(
            (item) => item.ID === res.ID
          );
          _this.resObj.DataList.splice(index, 1);
          _this.$Message.success("删除成功~");
        } else {
          _this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        _this.closeModal();
      }
    },
  },
  components: { jobEdit, detailDrawer, Layout },
};
</script>

<style lang="less">
.main-container {
  height: 100%;
}
</style>
